function showJSTextIcons() {
	const componentClass = 'c-default-text-icons';

	$(`.${componentClass}__expand-btn`).on('click', function() {
		$(this).toggleClass(`${componentClass}__expand-btn--active`);
		$(this)
			.siblings(`.${componentClass}__info-main-wrapper`)
			.slideToggle();
	});

	$(window).on('resize', function() {
		$(`.${componentClass}__expand-btn`).each(function() {
			if ($(window).outerWidth() >= 544) {
				if (
					!$(this).hasClass(`${componentClass}__expand-btn--active`)
				) {
					$(this)
						.siblings(`.${componentClass}__info-main-wrapper`)
						.slideDown();
				}
			} else {
				if ($(this).hasClass(`${componentClass}__expand-btn--active`)) {
					$(this)
						.siblings(`.${componentClass}__info-main-wrapper`)
						.slideDown();
				} else {
					$(this)
						.siblings(`.${componentClass}__info-main-wrapper`)
						.slideUp();
				}
			}
		});
	});
}

export default showJSTextIcons;
