export default () => {
	const componentClass = 'c-video';
	const video = $(`.${componentClass}__video--file`)[0];
	const playIcon = $(`.${componentClass}__play-icon`)[0];

	// Handle play button click
	$(document).on('click', `.${componentClass}__play-icon-wrapper`, () => {
		if (video.paused) {
			video.play();
			$(playIcon).hide();
		} else {
			video.pause();
			$(playIcon).show();
		}
	});
};
