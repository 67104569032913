import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';

export default () => {
	const componentClass = 'c-lu-quote-slider';
	Swiper.use([Autoplay, Navigation, Pagination]);

	const contentSwiper = new Swiper(
		`.${componentClass}__slider-tabs-content-container`,
		{
			slidesPerView: 1,
			allowTouchMove: true,
			navigation: {
				prevEl: `.${componentClass}__stories-slider-btn-prev`,
				nextEl: `.${componentClass}__stories-slider-btn-next`,
			},
			pagination: {
				el: `.${componentClass}__slider-pagination`,
				type: 'bullets',
				clickable: true,
			},
			breakpoints: {
				768: {
					allowTouchMove: false,
				},
			},
		},
	);

	// Handle tab click.
	$(document).on('click', `.${componentClass}__slider-tab-item`, evt => {
		const selectedItem = $(evt.target);
		// Remove active class
		evt.target.parentElement
			.querySelector(`.${componentClass}__slider-tab-item--active`)
			.classList.remove(`${componentClass}__slider-tab-item--active`);

		// Add new active class
		evt.target.classList.add(`${componentClass}__slider-tab-item--active`);
		contentSwiper.slideTo(selectedItem.data('index') ?? 0);
	});
};
