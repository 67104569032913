/*
 * hamburger
 *
 * runs the functionality of clicking the hamburger icon in the top nav
 */

let selector = '.js-hamburger';
let activeClass = 'is-active';
let bodyActiveClass = 'h-scroll-lock nav-active';
let headerActiveClass = 'l-header--color-menu';
let headerActiveClass2 = 'l-header--hamburger-active';
let navMenu = '.js-slide-out';
let navMenuActiveClass = 'c-slide-out--active';
let headerSearchButton = '.l-header__search-button ';
let searchBar = 'c-search-bar';

export function hamburger() {
	$(selector).click(function(e) {
		e.preventDefault();
		$(selector).toggleClass(activeClass);
		$('.js-body').toggleClass(bodyActiveClass);
		$('.js-header').toggleClass(headerActiveClass);
		$('.js-header').toggleClass(headerActiveClass2);
		$(navMenu).toggleClass(navMenuActiveClass);

		$(headerSearchButton).fadeToggle(100);

		if ($(`.${searchBar}`).hasClass(`${searchBar}--active`)) {
			$(selector)
				.parent()
				.siblings(headerSearchButton)
				.trigger('click');
		}
	});

	$(window).on('resize', function() {
		if ($(window).width() >= 992) {
			if ($('.header__hamburger').hasClass('is-active')) {
				$('.header__hamburger').trigger('click');
			}
		}
	});
}

export function hamburgerClose() {
	$(selector).removeClass(activeClass);
	$('.js-body').removeClass(bodyActiveClass);
	$('.js-header').removeClass(headerActiveClass);
	$('.js-header').removeClass(headerActiveClass2);
	$(navMenu).removeClass(navMenuActiveClass);
}
